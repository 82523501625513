var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mockInterview"},[_vm._m(0),_c('div',{staticClass:"containers"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"priceBanner"},[_c('div',{staticClass:"priceBannerArea"},[_c('div',{staticClass:"priceBannerContent"},[_c('img',{attrs:{"src":require("@/assets/imgs/priceBanner.png"),"alt":""}}),_c('div',{staticClass:"priceBannerInner"},[_c('div',{staticClass:"priceLeft"},[_c('div',{staticClass:"prices"},[_c('span',{staticClass:"mainTitle"},[_vm._v(_vm._s(_vm.classInfo.courseName))]),_c('span',{staticClass:"subTitle"},[_vm._v("服务价格：")]),_c('span',{staticStyle:{"font-size":"28px","color":"#de1306","line-height":"42px","vertical-align":"top"}},[_vm._v(" $"+_vm._s(_vm.classInfo.usdAmount))]),_c('span',{staticStyle:{"font-size":"16px","color":"#e02020","line-height":"42px","vertical-align":"top"}},[_vm._v(" / ¥"+_vm._s(_vm.classInfo.amount))])])]),_c('div',{staticClass:"priceRight"},[_vm._m(6),_c('el-button',{staticClass:"buyNow",on:{"click":_vm.buyCombo}},[_vm._v("立即购买")])],1)])])])])]),_c('leetDialog')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagebanner"},[_c('img',{attrs:{"src":require("@/assets/mock/jobPlanning/indexBanner.jpg"),"alt":""}}),_c('div',{staticClass:"pageTitle"},[_vm._v(" 1v1北美求职规划 "),_c('div',{staticClass:"subtitle"},[_vm._v(" 1v1对话创始人，针对性答疑，梳理留学找工问题 "),_c('br'),_vm._v(" 从项目到简历，算法刷题规划，整体求职路径安排 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("六大服务重点，北美求职规划")]),_c('p',[_vm._v("针对北美CS专业，特有的北美SDE求职之路")])]),_c('div',{staticClass:"six-points"},[_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 竞争力评估 "),_c('div',{staticClass:"des"},[_vm._v(" 个人背景评估 "),_c('br'),_vm._v(" 软 / 硬实力评估 ")])]),_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 简历重塑 "),_c('div',{staticClass:"des"},[_vm._v(" 简历整体修改指导 "),_c('br'),_vm._v(" 优势点重塑 ")])]),_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 算法指导 "),_c('div',{staticClass:"des"},[_vm._v(" 算法能力提升指导 "),_c('br'),_vm._v(" Coding 能力突破 ")])]),_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 导师1V1 "),_c('div',{staticClass:"des"},[_vm._v(" 资深导师1v1指导 "),_c('br'),_vm._v(" 个性化规划指导 ")])]),_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 内推指导 "),_c('div',{staticClass:"des"},[_vm._v("拒绝简历盲目海投"),_c('br'),_vm._v("内推方式路径推荐")])]),_c('div',{staticClass:"points"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 工业级项目 "),_c('div',{staticClass:"des"},[_vm._v(" 简历没有项目 "),_c('br'),_vm._v(" 工业级项目充实简历 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("留学生求职迷茫阶段，转专业最强解决方案")]),_c('p',[_vm._v("北美留学求职解决之路，让你不再迷茫")])]),_c('div',{staticClass:"plans"},[_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/jobPlanning/plan1.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("转专业零基础")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 针对转专业和零基础的同学 "),_c('br'),_vm._v(" 结合个人现状，确定合适发展目标和路径 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/jobPlanning/plan2.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("求职时间安排")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 找实习找全职时间规划安排 "),_c('br'),_vm._v(" 学校课程轻重，算法刷题时间安排 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/jobPlanning/plan3.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("CPT & OPT 身份")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 临毕业如何解决身份 "),_c('br'),_vm._v(" 如何最大化利用时间，拖时间搞定身份 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/jobPlanning/plan4.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("面试机会途径")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 如何拿到面试，面试机会小技巧 "),_c('br'),_vm._v(" 综合性求职解决方案，求职之路清晰明了 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("目标一线互联网大厂面试")]),_c('img',{staticStyle:{"width":"900px","margin":"0 auto","margin-top":"48px"},attrs:{"src":require("@/assets/mock/mockInterview/logohui.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section gray"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("服务形式")]),_c('div',{staticClass:"title-description"},[_vm._v("Edward老师1v1指导（60-90分钟）")]),_c('div',{staticClass:"title-description"},[_vm._v(" Online模式：无地区限制，使用Skype远程音频 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("快速报名，获取你的专属定制面试策略")])]),_c('div',{staticClass:"fourSteps"},[_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon1.png"),"alt":""}}),_c('br'),_vm._v(" 免费咨询 "),_c('br'),_vm._v(" 定制专属服务 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon2.png"),"alt":""}}),_c('br'),_vm._v(" 在线支付 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" Edward老师 "),_c('br'),_vm._v(" 亲自1v1规划指导 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon4.png"),"alt":""}}),_c('br'),_vm._v(" 1v1结构化面试 "),_c('br'),_vm._v(" 收获完整面试报告 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricesTips"},[_c('div',{staticClass:"dollar"},[_vm._v("支持美元分期付款")]),_c('div',{staticClass:"huabei"},[_vm._v("支持花呗分期")])])
}]

export { render, staticRenderFns }