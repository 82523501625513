<template>
  <!-- 求职规划 -->
  <div class="mockInterview">
    <div class="pagebanner">
      <img src="@/assets/mock/jobPlanning/indexBanner.jpg" alt="" />
      <div class="pageTitle">
        1v1北美求职规划
        <div class="subtitle">
          1v1对话创始人，针对性答疑，梳理留学找工问题
<!--          转专业，零基础规划指导，北美求职路径全程规划-->
          <br />
          从项目到简历，算法刷题规划，整体求职路径安排
<!--          定制专属你的留学求职方案，帮你解决留学找工困境-->
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="g-section">
        <div class="g-header">
          <h3>六大服务重点，北美求职规划</h3>
          <p>针对北美CS专业，特有的北美SDE求职之路</p>
        </div>
        <div class="six-points">
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            竞争力评估
            <div class="des">
              个人背景评估
              <br />
              软 / 硬实力评估
            </div>
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            简历重塑
            <div class="des">
              简历整体修改指导
              <br />
              优势点重塑
            </div>
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            算法指导
            <div class="des">
              算法能力提升指导
              <br />
              Coding 能力突破
            </div>
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            导师1V1
            <div class="des">
              资深导师1v1指导
              <br />
              个性化规划指导
            </div>
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            内推指导
            <div class="des">拒绝简历盲目海投<br />内推方式路径推荐</div>
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            工业级项目
            <div class="des">
              简历没有项目
              <br />
              工业级项目充实简历
            </div>
          </div>
        </div>
      </div>

      <div class="g-section">
        <div class="g-header">
          <h3>留学生求职迷茫阶段，转专业最强解决方案</h3>
          <p>北美留学求职解决之路，让你不再迷茫</p>
        </div>
        <div class="plans">
          <div class="plan">
            <img src="@/assets/mock/jobPlanning/plan1.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">转专业零基础</div>
              <div class="plan-description">
                针对转专业和零基础的同学
                <br />
                结合个人现状，确定合适发展目标和路径
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/jobPlanning/plan2.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">求职时间安排</div>
              <div class="plan-description">
                找实习找全职时间规划安排
                <br />
                学校课程轻重，算法刷题时间安排
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/jobPlanning/plan3.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">CPT &amp; OPT 身份</div>
              <div class="plan-description">
                临毕业如何解决身份
                <br />
                如何最大化利用时间，拖时间搞定身份
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/jobPlanning/plan4.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">面试机会途径</div>
              <div class="plan-description">
                如何拿到面试，面试机会小技巧
                <br />
                综合性求职解决方案，求职之路清晰明了
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="g-section">
        <div class="g-header">
          <h3>目标一线互联网大厂面试</h3>
          <img
            style="width: 900px; margin: 0 auto; margin-top: 48px"
            src="@/assets/mock/mockInterview/logohui.png"
            alt=""
          />
        </div>
      </div>
      <div class="g-section gray">
        <div class="g-header">
          <h3>服务形式</h3>
          <div class="title-description">Edward老师1v1指导（60-90分钟）</div>
          <div class="title-description">
            Online模式：无地区限制，使用Skype远程音频
          </div>
        </div>
      </div>
      <div class="g-section">
        <div class="g-header">
          <h3>快速报名，获取你的专属定制面试策略</h3>
        </div>
        <div class="fourSteps">
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon1.png"
              alt=""
            />
            <br />
            免费咨询
            <br />
            定制专属服务
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon2.png"
              alt=""
            />
            <br />
            在线支付
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon3.png"
              alt=""
            />
            <br />
            Edward老师
            <br />
            亲自1v1规划指导
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon4.png"
              alt=""
            />
            <br />
            1v1结构化面试
            <br />
            收获完整面试报告
          </div>
        </div>
      </div>

      <div class="priceBanner">
        <div class="priceBannerArea">
          <div class="priceBannerContent">
            <img src="@/assets/imgs/priceBanner.png" alt="" />
            <div class="priceBannerInner">
              <div class="priceLeft">
                <div class="prices">
                  <span class="mainTitle">{{ classInfo.courseName }}</span>
                  <span class="subTitle">服务价格：</span>
                  <span
                    style="
                      font-size: 28px;
                      color: #de1306;
                      line-height: 42px;
                      vertical-align: top;
                    "
                  >
                    ${{ classInfo.usdAmount }}</span
                  >
                  <span
                    style="
                      font-size: 16px;
                      color: #e02020;
                      line-height: 42px;
                      vertical-align: top;
                    "
                    >&nbsp;/&nbsp;&yen;{{ classInfo.amount }}</span
                  >
                </div>
              </div>
              <div class="priceRight">
                <div class="pricesTips">
                  <div class="dollar">支持美元分期付款</div>
                  <div class="huabei">支持花呗分期</div>
                </div>
                <el-button class="buyNow" @click="buyCombo">立即购买</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<leetDialog></leetDialog>
  </div>
</template>
<script>
import mockMix from "@/utils/mockMix";
import leetDialog from "@/components/leetDialog.vue";
export default {
  name: "Index",
  components: {
  	leetDialog
  },
  data() {
    return {};
  },
  mixins: [mockMix],
};
</script>
<style scoped lang="scss">
.pagebanner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .pageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    .subtitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.g-section {
  width: 100%;
  margin: 0 auto;
  padding: 36px 0;
  &.gray {
    background: rgba(240, 241, 242, 0.8);
  }
  .g-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .g-header h3 {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .g-header p {
    margin-top: 8px;
    color: #676767;
    font-size: 16px;
    margin-bottom: 48px;
  }
}

.plans {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .plan {
    width: 520px;
    padding: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 30px;
    img {
      vertical-align: top;
      width: 146px;
    }
    .plan-rightText {
      display: inline-block;
      width: calc(100% - 146px);
      .plan-title {
        padding: 16px 0;
        padding-top: 32px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e59f3c;
        line-height: 24px;
      }
      .plan-description {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }
    }
  }
}
.threeInterviews {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .interviews {
    text-align: center;
  }
  .interviewIcon {
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    padding: 30px;
    margin: 0 60px;
    img {
      width: 42px;
      height: 42px;
      vertical-align: top;
    }
  }
  .interviewType {
    margin-top: 20px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e59f3c;
    line-height: 27px;
  }
}
.fourSteps {
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .arrow,
  .step {
    margin: 0 24px;
  }
  .step {
    text-align: center;
    padding: 62px 54px;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 28px;
  }
  .arrow {
    line-height: 166px;
    img {
      width: 24px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.title-description {
  margin-top: 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 24px;
}
.getInIcon {
  width: 36px;
  margin-bottom: 24px;
}

.priceBanner {
  width: 100%;
  min-height: 60px;
  position: fixed;
  bottom: 50px;
  z-index: 99;
}
.priceBannerArea {
  width: 1200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.priceBannerContent {
  position: relative;
}
.priceBannerInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}
.pricesTips .dollar {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
}
.pricesTips .huabei {
  background: rgba(10, 122, 255, 0.1);
  color: #0a7aff;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
}
.priceBannerInner .prices {
  padding-bottom: 0px;
  color: #ff3b30;
  font-size: 24px;
  min-height: 30px;
  .mainTitle {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 42px;
    margin-right: 30px;
  }
  .subTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 42px;
    vertical-align: top;
    margin-right: 16px;
  }
}
.priceLeft,
.priceRight {
  display: inline-block;
}
.priceLeft {
  padding-left: 60px;
}
.priceRight {
  float: right;
  padding-right: 82px;
}
.priceRight .classHelp {
  line-height: 35px;
  background: #ff9f0a;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceRight .buyNow {
  line-height: 35px;
  background: #ff3b30;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceBannerContent img {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
.pricesTips {
  display: inline-block;
  margin-right: 12px;
}
.six-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
  .points {
    width: 220px;
    padding: 40px 0;
    text-align: center;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 18px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    background: #fff;
    margin: 0 40px;
    margin-bottom: 32px;
    border-radius: 8px;
    img {
      vertical-align: top;
      margin-bottom: 12px;
      width: 32px;
      height: 32px;
    }
    .des {
      margin-top: 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #757575;
      line-height: 20px;
    }
  }
}
</style>